
body {
  margin: 0;
}

.app-container {
  width: 100vw;
  height: 100vh;
}

.full-height {
  height: 100vh;
}
